<template>
  <div class="GroupSearchWrapper">
    <el-autocomplete v-model="searchStr"
      class="SearchInput"
      ref="inlineInput"
      size="medium"
      clearable
      trigger-on-focus
      :maxlength="30"
      highlight-first-item
      :fetch-suggestions="onQuerySearch"
      :placeholder="inputStr"
      @select="onSerachSelect"
      @clear="onClear">
      <template #prefix>
        <div class="SearchPrefix">
          <ElIcon :size="20"
            name="el-icon-search"></ElIcon>
        </div>
      </template>
      <template v-if="$scopedSlots.append"
        #append>
        <slot name="append"></slot>
      </template>

      <template #default="{item}">
        <template v-if="isGroup(item)">
          <Iconfont name="icon-chezu"
            :mRight="7"></Iconfont>
          <label class="pointer">{{item.groupName}}</label>
        </template>
        <template v-else>
          <Iconfont name="icon-cheliangguanli1"
            :mRight="3"
            :size="18"></Iconfont>
          <label class="pointer"
            :title="getVehicleTitle(item)">{{item.plate}}</label>
        </template>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
/**
 * 车组车辆搜索框
 */

import { throttle } from 'lodash';
import { searchMsg } from '@/api/getManagementData';
import { TreeNodeType, getTreeNodeId } from '@/utils/treeHelper.js';

export default {
  name: 'GroupSearch',
  emits: [
    // 选择事件
    'select',
    // 清除事件
    'clear',
  ],
  props: {
    // 搜索组织
    showGroup: {
      type: Boolean,
      default: true,
    },
    // 搜索车辆
    showVehicle: {
      type: Boolean,
      default: true
    },
    /**
     * 显示的最大项
     */
    maxCount: {
      type: Number,
      default: 100
    },
    inputStr: {
      type: String,
      default: '组织/设备名称'
    }
  },
  data() {
    return {
      // 搜索框显示文本
      searchStr: '',
    };
  },
  beforeMount() {
    this.onQuerySearch = throttle(this.onQuerySearch, 1000).bind(this);
  },
  methods: {
    isGroup(item) {
      return item.nodeType === TreeNodeType.isGroup;
    },
    /**
     * 拼接内容
     */
    getVehicleTitle(item) {
      return `${ item.plate };${ item.terminalNo };${ item.terminalType }`;
    },
    /**
     * 搜索
     */
    async onQuerySearch(queryStr, cb) {
      const { showGroup, showVehicle, maxCount } = this;

      let list = [];
      try {

        const qStr = queryStr.trim().toLowerCase();
        if (!qStr) return;

        const result = await searchMsg({ param: qStr });

        if (result && result.flag === 1) {
          const { groupNameList, plateList } = result.obj;
          if (showGroup) {
            groupNameList
              .forEach(group => {
                group.key = getTreeNodeId(group.groupId);
                group.nodeType = TreeNodeType.isGroup;
                list.push(group);
              });
          }
          if (showVehicle) {
            plateList
              .forEach(vehicle => {
                vehicle.key = getTreeNodeId(vehicle.groupId, vehicle.vehicleId);
                vehicle.nodeType = TreeNodeType.isVehicle;
                list.push(vehicle);
              });
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        cb(list.slice(0, this.maxCount));
      }

    },
    /**
     * 选择事件
     */
    onSerachSelect(item) {
      if (item.nodeType === TreeNodeType.isGroup) {
        this.searchStr = item.groupName;
      }
      if (item.nodeType === TreeNodeType.isVehicle) {
        this.searchStr = item.plate;
      }
      this.$emit('select', item);
    },
    /**
     * 清空
     */
    onClear() {
      this.searchStr = '';
      // 解决搜索后清空再次搜索无效问题
      this.$refs.inlineInput.handleFocus();
      // document.activeElement.blur()
      this.$emit('clear');
    }

  }
}
</script>

<style lang="scss" scoped>
.GroupSearchWrapper {
  display: flex;
  align-items: center;
}
.SearchPrefix {
  height: 100%;
  display: flex;
  align-items: center;
}
.SearchInput {
  width: 100%;
  ::v-deep .el-input-group__append {
    padding: 0 10px !important;
  }
}
.pointer {
  cursor: pointer;
}
</style>