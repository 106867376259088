<template>
  <Split class="VodWrapper">
    <template #first>
      <VodSide ref="vodSideRef"
        :searchCallback="onSearch"></VodSide>
    </template>

    <template #second>
      <Split direction="row-reverse"
        :padding="0">
        <template #first>
          <VodMap ref="mapRef"></VodMap>
        </template>
        <template #second>
          <VodPlayer ref="playerRef"
            :videoCloseTime="vodConfig.videoCloseTime"
            @playStart="onPlayStart"
            @playEnd="onPlayEnd"></VodPlayer>
        </template>
      </Split>
    </template>
  </Split>
</template>

<script>
import { mapState } from 'vuex';

import { sleep } from '@/utils/sleep';
import Split from '@/components/Split';
import VodSide from './VodSide.vue';
import VodPlayer from './VodPlayer.vue';
import VodMap from './VodMap.vue';

export default {
  // `name`与路由保持一致
  name: 'VideoPlayback',
  components: {
    Split,
    VodSide,
    VodPlayer,
    VodMap,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['userConfig']),
    vodConfig() {
      return this.userConfig.vodConfig;
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(newRoute, oldRoute) {
        const { vehicle } = newRoute.params;
        if (!vehicle) return;
        await sleep(1000);
        this.$nextTick(() => {
          this.$refs.vodSideRef.$children[0].$children[1].onNodeClicks(vehicle);
        });
        const { vodSideRef } = this.$refs;
        vodSideRef && vodSideRef.search(vehicle, 1000);
      }
    }
  },
  mounted() {
  },
  methods: {
    async onSearch(args) {
      const { playerRef } = this.$refs;
      if (playerRef) {
        await playerRef.query(args);
      }
    },
    onPlayStart({ groupId, vehicleId }) {
      const { mapRef } = this.$refs;
      if (mapRef) {
        mapRef.startTrack(groupId, vehicleId);
      }
    },
    onPlayEnd() {
      const { mapRef } = this.$refs;
      if (mapRef) {
        mapRef.stopTrack();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.VodWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.splitOuter {
  width: 500px;
  height: 400px;
  margin: 10px;
  // background: #fff;
}
</style>