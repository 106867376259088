<template>
  <div v-if="pointObj" class="VodWindowWrapper">
    <!-- 头部 -->
    <div class="Header">
      {{pointObj.plate}}
      <span class="closeIcon" @click.stop="$emit('close')">
        <ElIcon name="el-icon-close" title="关闭"></ElIcon>
      </span>
    </div>
    <!-- 内容 -->
    <div class="ContentOuter">
      <div class="Row">
        <div class="RowTitle">速度</div>
        <div class="RowContent">{{ pointObj.speed }} km/h</div>
      </div>
      <div class="Row">
        <div class="RowTitle">里程</div>
        <div class="RowContent">{{ pointObj.mileage }} km</div>
      </div>

      <div class="Row">
        <div class="RowTitle">状态</div>
        <div class="RowContent justify-between">
          <!-- <span>{{ pointObj.isACC === 0 ? 'ACC开' : 'ACC关'}}</span> -->
          <span :class="[colorData[vehicle.Z] || 'NeverOnline']">{{ getStateLabel }}</span>
        </div>
      </div>
      <div class="Row">
        <div class="RowTitle">车组</div>
        <div class="RowContent">{{pointObj.groupName}}</div>
      </div>
      <div class="Row">
        <div class="RowTitle">地址</div>
        <div class="RowContent">
          <AddressText
            v-model="pointObj.address"
            :lng="pointObj.lon"
            :lat="pointObj.lat"
          ></AddressText>
        </div>
      </div>
      <div class="Row">
        <div class="RowTitle">设备时间</div>
        <div
          class="RowContent"
        >{{ dayjs(pointObj.devTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
      <div class="Row">
        <div class="RowTitle">报警</div>
        <div class="RowContent">{{ pointObj.alarm }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import AddressText from '@/components/AddressText';


export default {
  name: 'VodWindow',
  components: {
    AddressText,
  },
  data() {
    return {
      colorData: {
        0: 'yellow',
        1: 'NeverOnline',
        2: 'Online',
        3: 'blue',
        4: 'Offline',
        5: 'NeverOnline',
      }
    }
  },
  props: {
    pointObj: {
      type: Object,
      default: null,
    },

  },
  computed: {
    getStateLabel() {
      const { pointObj } = this;
      if (pointObj) {
        // if (pointObj.Z === 0) return '离线';
        // if (pointObj.Z === 1) return '行驶';
        // if (pointObj.Z === 2) return '停车';
        
        if (pointObj.Z === 0) return '怠速';
        if (pointObj.Z === 1) return '行驶';
        if (pointObj.Z === 2) return '作业';
        if (pointObj.Z === 3) return '通电';
        if (pointObj.Z === 4) return '熄火';
        if (pointObj.Z === 5) return '离线';
        return "从未上线";
      }
      return '';
    },
  },
  methods: {
    dayjs,

  }
}
</script>

<style lang="scss" scoped>
.VodWindowWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Header {
  height: 28px;
  background: #212d3e;
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.ContentOuter {
  height: calc(100% - 28px);
  display: flex;
  padding: 10px;
  flex-direction: column;
  font-size: 12px;
  overflow: auto;
}
.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 7px;
}
.RowTitle {
  padding-right: 10px;
  font-size: 12px;
  color: #a4a4a4;
}
.RowContent {
  color: #000000;
  flex: 1;
  overflow: hidden;
}
.stateSpan {
  width: 34px;
  height: 16px;
  background: #57d86d;
  border: 1px solid #3dc854;
  border-radius: 2px;

  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeIcon {
  position: absolute;
  right: 10px;
}
.tabContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}
</style>