import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl)


/**
 * 
 * @param {JSON} val 
 */
export const playbackVheicle = (val) => { return axios.get('monitor/playbackVheicle.do', { params: val }) }

/**
 * 
 * @param {JSON} val 
 */
export const getDownloadInfo = (val) => { return axios.get('/ftpFileInfo/getDownloadInfo.do', { params: val }) }

