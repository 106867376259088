<template>
  <ResizeObserver @resize="handleWrapperResize">
    <div ref="lineOuter" class="ChannelTimeLineWrapper" @click="onClick">
      <div class="timeContainer">
        <div
          v-for="range in rangeList"
          :key="range.startTime"
          :style="{width:`${getRangeWidthPtg(range.startTime, range.endTime)}%`}"
          :class="['timeFragment', !!range.fileList && 'timeActive']"
        ></div>
      </div>

      <!-- 校准线 -->
      <div :style="axisStyle" class="TimeAxis"></div>

      <!-- 提示 -->
      <div
        :style="tipStyle"
        v-show="showTip"
        class="AxisTip"
      >{{newChannel}} {{tipTimeFormat}}</div>
    </div>
  </ResizeObserver>
</template>

<script>
import ResizeObserver from '@/components/ResizeObserver';
import dayjs from 'dayjs';
import { throttle } from 'lodash';

// 一小时`3600000`毫秒
const oneHourMs = 1000 * 60 * 60;
// 一天`86400000`毫秒
const oneDayMs = oneHourMs * 24;
const tipWidth = 200;

const todayStart = dayjs(dayjs().format('YYYY-MM-DD 00:00:00.000'));
const todayStartMs = todayStart.valueOf();

export default {
  name: 'ChannelTimeLineWrapper',
  components: {
    ResizeObserver
  },
  model: {
    prop: 'axisBasis',
  },
  props: {
    /**
     * 滑动占比
     */
    axisBasis: {
      type: Number,
      default: 0,
      validator(val) {
        return val >= 0 && val <= 1;
      }
    },
    /**
     * 开始时间(毫秒)
     */
    startPos: {
      type: Number,
      default: todayStartMs,
    },
    /**
     * 结束时间(毫秒)
     */
    endPos: {
      type: Number,
      default: todayStartMs + oneDayMs - 1,
    },
    /**
     * 通道号
     */
    channel: {
      type: [Number, String],
      default: '',
      required: true,
    },
    /**
     * 文件列表
     */
    timeList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      // 记录外部元素的`getBoundingClientRect`
      outerRect: null,
      // 移动百分比
      movePtg: 0,
      // 是否显示提示时间, 超出后不提示
      showTip: false,
      // 提示时间的位置
      tipPosition: {
        top: 0,
        left: 0,
      }
    };
  },
  computed: {
    newChannel(){
      switch(this.channel){
        case 1:
          return '右摄像头';
        case 2:
          return '后摄像头';
        case 3:
          return '左摄像头';
        case 4:
          return '前摄像头';
        case 5:
          return '驾驶室内摄像头';
        case 6:
          return '卷扬摄像头';
      }      
    },
    /**
     * 基准线的样式
     */
    axisStyle() {
      const { movePtg } = this;
      const styleObj = {
        flexBasis: `${movePtg * 100}%`,
      };

      return styleObj;
    },
    /**
     * 提示时间的样式
     */
    tipStyle() {
      const { top, left } = this.tipPosition;
      return {
        width: `${tipWidth}px`,
        top: `${top}px`,
        left: `${left}px`
      };
    },
    /**
     * 鼠标滑动当前时间
     */
    currentPos() {
      const { movePtg, startPos, endPos } = this;
      return startPos + Math.floor((endPos - startPos) * movePtg);
    },
    /**
     * 当前时间格式化显示
     */
    tipTimeFormat() {
      const { currentPos } = this;
      return dayjs(currentPos).format('YYYY-MM-DD HH:mm:ss');
    },
    /**
     * 时间渲染列表
     */
    rangeList() {
      const { timeList, startPos, endPos } = this;

      if (!timeList.length) return [];

      const tmpList = [...timeList];
      for (let i = tmpList.length - 1; i > 0; i--) {
        const timeObj = tmpList[i];
        const prevTimeObj = tmpList[i - 1];
        tmpList.splice(i, 0, {
          startTime: prevTimeObj.endTime,
          endTime: timeObj.startTime,
        });
      }


      // 处理开始处
      if (tmpList[0].startTime > startPos) {
        tmpList.splice(0, 0, {
          startTime: startPos,
          endTime: tmpList[0].startTime,
        });
      }

      // 处理结束出
      if (tmpList[tmpList.length - 1].endTime < endPos) {
        tmpList.push({
          startTime: tmpList[tmpList.length - 1].endTime,
          endTime: endPos,
        });
      }
      return tmpList;
    }
  },
  watch: {
    axisBasis(val) {
      this.movePtg = val;
    },
  },
  mounted() {
    // 初次计算容器属性
    this.calcOuterRect();
  },
  created() {
    this.onMousemove = throttle(this.onMousemove, 100).bind(this);
    document.addEventListener('mousemove', this.onMousemove);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onMousemove);
  },
  methods: {
    handleWrapperResize() {
      this.calcOuterRect();
    },
    async calcOuterRect() {
      this.$nextTick();
      const { lineOuter } = this.$refs;
      if (!lineOuter) return;

      const rect = lineOuter.getBoundingClientRect();

      this.outerRect = {
        startX: Math.ceil(rect.x),
        startY: Math.ceil(rect.y),
        totalWidth: Math.ceil(rect.width),
        totalHeight: Math.ceil(rect.height),
      };
    },
    /**
     * 判断是否在通道内部
     */
    contains(pageX, pageY) {

      let { startX, startY, totalWidth, totalHeight } = this.outerRect;

      let endX = startX + totalWidth;
      let endY = startY + totalHeight;
      startX -= 1;
      endX += 1;

      return pageX >= startX
        && pageY >= startY
        && pageX <= endX
        && pageY <= endY;

    },
    /**
     * 鼠标离开
     */
    onMouseleave() {
      this.showTip = false;
    },
    /**
     * 鼠标滑动
     */
    onMousemove(e) {
      const pageX = e.pageX;
      const pageY = e.pageY;

      // 超出范围, 则隐藏提示时间
      if (!this.contains(pageX, pageY)) {
        this.showTip = false;
        return;
      }

      this.showTip = true;

      const { outerRect } = this;
      // 计算鼠标当前位置到时间轴开始的占比
      let ptg = (pageX - outerRect.startX) / outerRect.totalWidth;
      ptg = Math.max(ptg, 0);
      ptg = Math.min(ptg, 1);

      this.movePtg = ptg;

      if (!this.contains(pageX + tipWidth + 20, pageY)) {
        // 向右偏移15px
        this.tipPosition.left = (pageX - tipWidth) - 15;
      } else {
        // 向左偏移15px
        this.tipPosition.left = pageX + 15;
      }
      // 向下偏移5px
      this.tipPosition.top = pageY + 5;

      this.$emit('input', ptg);

      this.$nextTick(() => {
        const { currentPos, channel } = this;
        this.$emit('moving', channel, currentPos);
      });
    },
    /**
     * 点击时间轴
     */
    onClick() {
      const { currentPos, channel, rangeList } = this;
      if (!rangeList.length) return;


      // 找出当前时间所在的时间范围
      const { startTime, endTime, fileList } = rangeList
        .find(p => p.startTime <= currentPos && p.endTime >= currentPos);

      this.$emit('click', currentPos, { channel, startTime, endTime, fileList });
    },
    /**
     * 根据开始,结束时间计算宽度
     */
    getRangeWidthPtg(startTime, endTime) {
      const { startPos, endPos } = this;
      return Math.floor((endTime - startTime) / (endPos - startPos) * 10000) / 100;
    }
  }
}
</script>

<style lang="scss" scoped>
.ChannelTimeLineWrapper {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: row;
  border: 1px solid #d6d6d6;

  &:not(&:first-child) {
    border-top-width: 0;
  }
}
.TimeAxis {
  height: 100%;
  border-right: 1px #409eff solid;
  cursor: pointer;
  z-index: 10;
}
.AxisTip {
  position: fixed;
  background-color: #0071c6;
  color: #fff;
  border-radius: 10px;
  opacity: 0.8;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  z-index: 20;
}
.timeContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.timeFragment {
  height: calc(100% - 4px);
}

.timeActive {
  background-color: #1afa29;
}
</style>