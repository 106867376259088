<template>
  <div class="VehicleGroupWrapper">
    <!-- 搜索区域 -->
    <div v-if="showSearch"
      class="SearchBox">
      <SearchBox class="SearchBoxInner"
        @select="onSerachSelect"
        :inputStr="inputStr">
        <template #append>
          <div class="SearchAppend"
            title="点击刷新数据"
            @click.stop="reloadData">
            <ElIcon :size="20"
              :name="treeLoading ? 'el-icon-loading': 'el-icon-refresh'"></ElIcon>
          </div>
        </template>
      </SearchBox>
    </div>

    <!-- 虚拟树形区域 -->
    <div v-loading="treeLoading"
      class="VehicleGroupTree">
      <VirtualTree ref="virtualTreeRef"
        :defaultExpandAll="defaultExpandAll"
        :defaultExpandKeys="defaultExpandKeys"
        :defaultCheckedKeys="defaultCheckedKeys"
        :defaultDisabledKeys="defaultDisabledKeys"
        :showCheckbox="showCheckbox"
        :visibleFilter="visibleFilter"
        :emptyText="emptyText"
        :data="groupList"
        :rootParentKey="null"
        :fieldNames="{
        key:'key',
        parentKey:'parentKey',
        label:'label'
      }"
        :clickDelay="clickDelay"
        @loaded="onTreeLoaded"
        @node-click="onNodeClick"
        @node-dblclick="onNodeDblclick"
        @check-change="onCheckChange">
        <template v-slot="{node, data}">
          <div class="GVNodeContent">
            <!-- 图标 -->
            <NodeIcon :data="data"
              :nodeType="data.nodeType"
              :renderIcon="renderIcon"></NodeIcon>

            <div class="GVNodeLabel">
              <template v-if="$scopedSlots.default">
                <slot :data="data"
                  :node="node"></slot>
              </template>
              <template v-else>{{node.label}}</template>
            </div>

            <!-- 操作插槽 -->
            <div action>
              <slot v-if="$scopedSlots.action"
                name="action"
                :data="data"
                :node="node"></slot>
            </div>
          </div>
        </template>
      </VirtualTree>
    </div>
  </div>
</template>

<script>
/**
 * 车组车辆树
 * 可配置是否更新车辆信息
 */


import { isObject } from 'lodash';
import { mapState } from 'vuex';
import VirtualTree from '@/components/VirtualTree';
import SearchBox from '@/components/GroupSearch';
import { loadGroups } from '@/api/getData.js';
import { loadVehicles } from '@/api/live-monitor-api';
import { arrayTreeSort, getTreeNodeId, TreeNodeType } from '@/utils/treeHelper.js';
import NodeIcon from '@/components/Icon/NodeIcon.vue';
import { sleepIf } from '@/utils/sleep';

// TODO: 设置复选框勾选状态
// TODO: 设置复选框禁用状态

export default {
  name: 'VehicleGroupTree',
  components: {
    VirtualTree,
    NodeIcon,
    SearchBox,
  },
  emits: [
    // 单击事件
    'node-click',
    // 双击事件
    'node-dblclick',
    // 车辆更新事件 `updateVehicleTime` 为 `0` 无效
    'updateVehicle',
    // 节点勾选事件
    'node-check',
    /**
     * 搜索选择事件
     * @param {Object} param 
     * - {key,nodeType,driverName,groupId,groupName,plate,sim,terminalNo,terminalType,vehicleId}
     */
    'searchSelect',
    // 车辆更新倒计时事件 `updateVehicleTime`为`0`无效
    'countdown',

    // 获取当前被选中节点的 `node`,
    'getCurrentNode'
  ],
  props: {
    /**
     * 是否显示搜索框
     */
    showSearch: {
      type: Boolean,
      default: false,
    },
    /**
     * 显示车辆(叶子节点)
     */
    showVehicle: {
      type: Boolean,
      default: false,
    },
    /**
     * 显示通道号
     */
    showChannel: {
      type: Boolean,
      default: false,
    },
    /**
     * 只显示视频设备, `showVehicle`为`true`有效
     */
    onlyVideo: {
      type: Boolean,
      default: false,
    },
    /**
     * 默认展开所有
     */
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    /**
     * 显示复选框
     */
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    /**
     * 车组是否显示复选框
     */
    groupCheckable: {
      type: Boolean,
      default: null,
    },
    /**
     * 车辆是否显示复选框
     */
    vehicleCheckable: {
      type: Boolean,
      default: null,
    },
    /**
     * 通道是否显示复选框
     */
    channelCheckable: {
      type: Boolean,
      default: null,
    },
    /**
     * 筛选显示的节点
     * (data)=>boolean
     */
    visibleFilter: {
      type: Function,
      default: null,
    },
    /**
     * 车辆更新间隔时间(秒)
     * 0: 不更新
     */
    updateVehicleTime: {
      type: Number,
      default: 0,
      validator(val) {
        return val === 0 || val >= 15;
      }
    },
    /**
     * 自定义渲染图标
     */
    renderIcon: {
      type: Function,
      default: null,
    },
    /**
     * 单击事件延迟触发,
     * NOTE: 单击事件会延迟200ms, 主要区分双击事件
     */
    clickDelay: {
      type: Number,
      default: 200,
    },
    // 默认展开的节点的 key 的数组
    defaultExpandKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认勾选节点的key的数组
    defaultCheckedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认禁用复选框节点的key的数组
    defaultDisabledKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    inputStr: {
      type: String,
      default: '组织/设备名称'
    }
  },
  data() {
    // 定时器句柄
    this.timerId = null;
    // 更新车辆的时间戳(后端接口返回)
    this.pmt = 0;
    // 更新车辆的时间戳(后端接口返回)
    this.vmt = 0;
    // 映射车组车辆数组
    this.groupMap = new Map();

    return {
      emptyText: '--',
      // 加载状态
      treeLoading: false,

      // 车组车辆数组
      groupList: [],
      // 车组Id集合
      groupIds: [],
      // 当前节点key
      currentKey: null,
    };
  },
  computed: {
    ...mapState(['userInfo'])
  },
  beforeMount() {
    // 初始加载渲染数据
    this.loadData();
  },
  beforeDestroy() {

    // 销毁, 重置
    clearInterval(this.timerId);
    this.timerId = null;
    this.pmt = 0;
    this.vmt = 0;
    this.groupList = [];
    this.groupMap.clear();
    this.groupMap = null;

  },
  methods: {
    // 更新车辆资料
    async updateVehicles() {
      try {
        const { groupIds, pmt, vmt, updateVehicleTime } = this;
        if (!updateVehicleTime) return;

        // 更新车辆资料
        const result = await loadVehicles({ groupIds, pmt, vmt });

        if (result.flag === 1) {

          const { pmt, vmt, data } = result.obj;
          // 记录时间戳
          this.pmt = pmt;
          this.vmt = vmt;

          // 已经更新的数据 [更新后的数据, 更新前的数据]
          // type: Array<[newVehicle, oldVehicle]>
          const hadUpdates = (data || [])
            .map(vehicle =>
              this.updateVehicle(vehicle)
            )
            // 选出有数据更新的
            .filter(arr => arr);

          // 如果有更新数据, 则触发更新车辆事件
          if (hadUpdates.length > 0) {
            // type: Array<[newVehicle, oldVehicle]>
            this.$emit('updateVehicle', hadUpdates);
          }
        }

      } catch (err) {
        //
      } finally {

      }
    },

    /**
     * 轮询更新车辆信息
     */
    startTiming() {
      const { updateVehicleTime } = this;
      clearInterval(this.timerId);
      this.timerId = null;
      // 计时器
      let count = updateVehicleTime;

      // 每秒钟定时, 可用于倒时提醒
      this.timerId = setInterval(() => {
        try {
          if (!this.timerId) return;
          count--;

          // 触发到时提醒事件
          this.$emit('countdown', count);

          // 满足更新时间后, 更新车辆信息, 并重置计时器
          if (count <= 0) {
            count = updateVehicleTime;
            this.updateVehicles();
          }

        } catch (err) {
          console.error(err);
        }

      }, 1000);
    },

    /**
     * 重新加载
     */
    async reloadData() {

      if (this.treeLoading) return;

      await this.loadData();

    },

    /**
     * 加载车组车辆资料
     */
    async loadData() {
      // 1. 加载车组资料
      // 2. 通过车组ID加载车辆资料
      const {
        showVehicle,
        updateVehicleTime,
        visibleFilter,
        showCheckbox,
        groupCheckable,
      } = this;

      // 重置, 初始化
      this.treeLoading = true;
      this.groupList = [];
      this.groupIds = [];

      try {
        const { onlyVideo } = this;
        const { userId } = this.userInfo;

        // 加载车组资料
        let result = await loadGroups(userId);
        this.setEmptyText(result?.msg);
        if (result.flag !== 1) return;  // 失败

        const groupList = result.obj
          // 筛选掉监管车组
          .filter(p => p.groupId !== 0 && p.parentId !== 0)
          // 为数组项添加`key`,`parentKey`,`nodeType`, `label`字段
          .map(group => {

            const groupNode = {
              // 车组类型
              nodeType: TreeNodeType.isGroup,
              // 唯一key
              key: getTreeNodeId(group.groupId),
              // 关联父key
              parentKey: getTreeNodeId(group.parentId),
              // `groupName`与`label`映射
              label: group.groupName,
              ...group,
            };

            // 设置车组是否默认可勾选状态(即是否可以被勾选)
            if (showCheckbox && groupCheckable !== null) {
              groupNode.checkable = groupCheckable;
            }

            // 存入Map
            if (!this.groupMap.has(groupNode.key)) {
              this.groupMap.set(groupNode.key, groupNode);
            }

            return groupNode;

          })
          .filter(item => !visibleFilter || visibleFilter(item));

        if (!groupList.length) return;

        const groupIds = groupList.map(group => group.groupId);
        this.groupIds = groupIds;

        // 显示车辆
        if (showVehicle) {

          // 加载所有车辆
          result = await loadVehicles({ groupIds });

          this.setEmptyText(result?.msg);

          if (result.flag === 1) {

            const { pmt, vmt, data } = result.obj;
            // 记录时间戳
            this.pmt = pmt;
            this.vmt = vmt;

            (data || [])
              .forEach(vehicle => {
                // 解析并重新封装车辆结构
                const [vehicleNode, channels] = this.toVehicleNode(vehicle);
                // `onlyVideo`为`true`时, 部标设备不显示
                if (!onlyVideo || vehicleNode.isVideo || vehicleNode.isSupportMedia) {
                  if (visibleFilter && !visibleFilter(vehicleNode)) return;

                  // 存入Map
                  if (!this.groupMap.has(vehicleNode.key)) {
                    this.groupMap.set(vehicleNode.key, vehicleNode);
                  }
                  // 添加车辆节点
                  groupList.push(vehicleNode);
                  // 添加通道节点
                  groupList.push(...channels);
                }
              });
          }
        }

        // 将数据转化为符合虚拟树的结构数据, 扁平化并按深度优先排序
        this.groupList = arrayTreeSort(groupList, null, { id: 'key', parentId: 'parentKey' });

        // 开始定时更新车辆资料
        if (groupList.length && updateVehicleTime > 0) {
          this.startTiming();
        }

      } catch (error) {
        console.error(error);
      } finally {
        // 定时后取消加载状态, 表明已经渲染完成  FIXME: 这里有问题, 不准确
        sleepIf(5000, () => !this.treeLoading)
          .then(() => this.treeLoading = false);
      }
    },

    /**
     * 更新车辆
     * @return {[newVehicle, oldVehicle]}
     */
    updateVehicle(newVehicle) {
      const key = getTreeNodeId(newVehicle.M, newVehicle.V);
      if (!this.groupMap.has(key)) return null;
      const vehicle = this.groupMap.get(key);

      // 存储更新字段 (NOTE: 没有变化的字段不会记录)
      const oldObj = {};

      // 用于计数
      let count = 0;
      for (let k in newVehicle) {
        // 字段未变法, 直接跳过
        if (vehicle[k] === newVehicle[k]) continue;

        oldObj[k] = vehicle[k];

        vehicle[k] = newVehicle[k];
        count += 1;
      }


      // 数据有更新: arr[0] !== arr[1]
      return count > 0 ? [vehicle, oldObj] : null;
    },

    /**
     * 组装车辆节点
     * @return {[vehicleNode, channels]} - vehicleNode 车辆节点 - channels 通道节点
     */
    toVehicleNode(vehicle) {
      const {
        showChannel,
        vehicleCheckable,
        showCheckbox,
        channelCheckable,
      } = this;

      // 是否是视频设备
      const isVideo = vehicle.isSupportMedia;
      const channels = [];

      const vehicleNode = {
        // 节点类型: 车辆
        nodeType: TreeNodeType.isVehicle,
        isVideo,
        key: getTreeNodeId(vehicle.M, vehicle.V),
        parentKey: getTreeNodeId(vehicle.M),
        // 设备名称`P`与`label`映射
        label: vehicle.P,
        address: '',
        ...vehicle,

        // TODO: 添加必要的响应式字段
      };

      // 车辆节点是否默认可勾选
      if (vehicleCheckable !== null) {
        vehicleNode.checkable = vehicleCheckable;
      }

      // 如果有, 处理通道
      if (showChannel && isVideo) {

        try {
          (JSON.parse(vehicle.camreaLine) || [])
            .forEach(c => {

              const channelNode = {
                nodeType: TreeNodeType.isChannel,
                groupId: vehicle.M,
                vehicleId: vehicle.V,
                channel: c,
                key: getTreeNodeId(vehicle.M, vehicle.V, c),
                parentKey: getTreeNodeId(vehicle.M, vehicle.V),
                label: `通道${ c }`
              };

              // 通道是否可勾选
              if (showCheckbox && channelCheckable !== null) {
                channelNode.checkable = channelCheckable;
              }

              channels.push(channelNode);
            });
        } catch (error) {
          //
        }
      }

      return [
        vehicleNode,
        channels,
      ];
    },

    // 设置无数据的显示文本
    setEmptyText(text = '--') {
      this.emptyText = text;
    },

    /**
     * 设置当前节点
     */
    setCurrentKey(key) {
      const { virtualTreeRef } = this.$refs;
      this.currentKey = key;
      virtualTreeRef?.setCurrentKey(key, true);
    },
    /**
     * 通过`key`获取节点
     */
    findNodeData(key) {
      const { groupMap } = this;
      return groupMap.get(key);
    },

    /**
     * 搜索选择事件
     */
    onSerachSelect(item) {
      const { showVehicle } = this;
      let key = item.key;
      // 通过`nodeType`判断车组,车辆
      // if (item.nodeType === TreeNodeType.isGroup) {
      // }

      // 未显示车辆时, 选择车辆则选择对应车组
      if (!showVehicle && item.nodeType === TreeNodeType.isVehicle) {
        key = getTreeNodeId(item.groupId);
      }

      // { key, nodeType, driverName, groupId, groupName, plate,  sim, terminalNo, terminalType, vehicleId}
      this.$emit('searchSelect', item);
      this.setCurrentKey(key);
    },

    // 单击事件
    onNodeClick(data, node) {
      this.currentKey = data.key;
      this.$emit('node-click', data, node);
    },
    // 双击事件
    onNodeDblclick(data, node) {
      this.$emit('node-dblclick', data, node);
    },
    // 复选框勾选事件
    onCheckChange(data, checked, node, allCheckeds) {
      this.$emit('node-check', data, checked, node, allCheckeds);
    },

    /**
     * 获取当前被选中节点的 `node`,
     * 若没有节点被选中则返回 null
     */
    getCurrentNode() {
      const { virtualTreeRef } = this.$refs;
      const data = virtualTreeRef?.getCheckedNodes();

      return data;
    },

    // tree加载完成, 非准确
    async onTreeLoaded() {
      await this.$nextTick();
      this.treeLoading = false;
      const { currentKey } = this;
      if (currentKey) {
        this.setCurrentKey(currentKey);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.VehicleGroupWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.SearchBox {
  height: 40px;
  margin-bottom: 3px;
  // background-color: #fff;
  display: flex;
  align-items: center;
}
.SearchBoxInner {
  width: 100%;
}
.VehicleGroupTree {
  flex: 1;
  overflow: hidden;
}
.GVNodeContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}
.GVNodeLabel {
  position: relative;
  width: calc(100% - 25px);
  height: 100%;
}
.SearchPrefix {
  height: 100%;
  display: flex;
  align-items: center;
}
.SearchInput {
  width: 100%;
  ::v-deep .el-input-group__append {
    padding: 0 10px !important;
  }
}
.SearchAppend {
  cursor: pointer;
}
</style>
