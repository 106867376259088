<template>
  <Iconfont v-bind="iconProps"></Iconfont>
</template>

<script>
/**
 * 树节点图标
 */

import { TreeNodeType } from '@/utils/treeHelper.js';

export default {
  name: 'NodeIcon',
  props: {
    data: {
      type: Object,
      default: null,
    },
    nodeType: {
      type: Number,
      default: TreeNodeType.isGroup,
    },
    /**
     * 自定义渲染Icon
     */
    renderIcon: {
      type: Function,
      default: null,
    },
    // 车辆状态
    vehicleState: {
      type: Number,
      default: null,
    },
  },
  computed: {
    NodeType() {
      return TreeNodeType;
    },
    iconProps() {
      const { renderIcon, vehicleState, nodeType } = this;

      let propsObj = {
        mRight: 7,
      };

      switch (nodeType) {
        case TreeNodeType.isVehicle:
          // propsObj.name = 'icon-cheliangguanli1';
          propsObj.name = 'icon-lixian';
          propsObj.size = 18;

          if (vehicleState !== null) {
            // if (vehicleState === 1) {
            //   // 行驶
            //   propsObj.name = 'icon-hangshi1';
            // } else if (vehicleState === 2) {
            //   // 停车
            //   propsObj.name = 'icon-tingche1';
            // } else {
            //   propsObj.name = 'icon-lixian';
            // }
            if (vehicleState === 0) {
              propsObj.name = "icon-baojing";
            } if (vehicleState === 1) {
              propsObj.name = "icon-baojing";
            } else if (vehicleState === 2) {
              propsObj.name = "icon-hangshi1";
            } else if (vehicleState === 3) {
              propsObj.name = "icon-tingche1";
            } else if (vehicleState === 4) {
              propsObj.name = "icon-baojing";
            }

          }

          break;
        case TreeNodeType.isChannel:
          propsObj.name = 'icon-tongdao';
          break;
        case TreeNodeType.isGroup:
        default:
          propsObj.name = 'icon-chezu';
          break;
      }

      if (renderIcon) {
        propsObj = {
          ...propsObj,
          ...(renderIcon(this.data) || {})
        };
      }

      return propsObj;

    }
  },
  mounted() {
  }
}
</script>