<template>
  <BSJMap>
    <MapMarker
      v-if="currentPoint"
      :offset="markerOffset"
      :rotate="0"
      :lngLat="markerLngLat"
      autoPanTo
    >
      <!-- <Iconfont :name="vehicleIconName" :size="32"></Iconfont> -->
      <Iconfont name="icon-a-zu517" :size="32"></Iconfont>
    </MapMarker>
    <MapPolyline v-if="path.length" :path="path"></MapPolyline>
    <InfoWindow
      ref="windowObj"
      v-if="currentPoint && path.length"
      v-model="infoVisible"
      :offset="windowOffset"
      :lngLat="markerLngLat"
      :width="280"
      :height="220"
    >
      <VodWindow :pointObj="currentPoint" @close="infoVisible=false"></VodWindow>
    </InfoWindow>
  </BSJMap>
</template>

<script>
import { BSJMap, InfoWindow, MapMarker, MapPolyline } from '@/components/AMap';
import {
  playbackVheicle,
} from '@/api/getVideoplayback.js';
import GPS from '@/utils/address';
import VodWindow from './VodWindow.vue';
// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import vehiclePicture from '@/utils/vehiclePicture';


export default {
  name: 'VodMap',
  components: {
    BSJMap,
    MapMarker,
    MapPolyline,
    InfoWindow,
    VodWindow,
  },
  data() {
    this.timerId = null;
    return {
      path: [],
      currentPoint: null,
      infoVisible: false,
      windowOffset: [0, -15],
      markerOffset: [15, -10],
    };
  },
  computed: {
    markerLngLat() {
      const { currentPoint } = this;
      if (!currentPoint) return [0, 0];

      const point = GPS.delta(currentPoint.lat, currentPoint.lon);
      return [point.lon, point.lat];
    },
    // 车辆类型图标
    vehicleIconName() {
      const { currentPoint } = this;
      return vehiclePicture(currentPoint.machineryProductType, currentPoint.machineryEquipmentType, (currentPoint.Z || currentPoint.vehicleStatus));
    },
  },
  mounted() {

  },
  beforeDestroy() {
    this.stopTrack();
  },
  methods: {
    // getProductTypeIconName,
    startTrack(groupId, vehicleId) {
      this.path = [];
      this.curerntPoint = [];
      this.timerId = setInterval(() => this.timerId && this.getTarck(groupId, vehicleId), 1000 * 15);
      this.getTarck(groupId, vehicleId);
    },
    stopTrack() {
      this.path = [];
      this.currentPoint = null;
      clearInterval(this.timerId);
      this.timerId = null;
    },
    async getTarck(groupId, vehicleId) {
      try {

        const result = await playbackVheicle({
          gid: groupId,
          vid: vehicleId,
        });

        if (result.flag !== 1) return;

        const obj = result.obj;

        if (obj.lat && obj.lon) {
          this.currentPoint = {
            ...obj,
            address: '',
          };
          const point = GPS.delta(obj.lat, obj.lon);
          this.path.push([point.lon, point.lat]);
          this.infoVisible = true;
        } else {
          // 无定位数据
          this.infoVisible = false;
          this.currentPoint = null;
        }


        //        obj:
        // BS: 0
        // HS: 0
        // LS: 1
        // LTS: 0
        // RTS: 0
        // alarm: ""
        // devTime: "2021-12-01 02:30:42"
        // direct: 576
        // isAcc: 0
        // lat: 37.274916
        // lon: 117.0957
        // mileage: 0
        // plate: "CU105B疲劳"
        // speed: 0
        // terType: "GF-1078"
        // vId: 317030
        // vehicleShape: "客车"
        // vehicleState: 2

      } catch (err) {
        //
      }
    }

  }
}
</script>