<template>
  <div class="VodFooter">
    <div class="TypeSwitcher">
      <el-button
        :type="isTimeline ? 'primary' : ''"
        @click.stop="isTimeline = true"
      >时间</el-button>
      <el-button
        :type="(!isTimeline) ? 'primary' : ''"
        @click.stop="isTimeline = false"
      >文件</el-button>
    </div>
    <div class="FooterContent">
      <div v-show="isTimeline">
        <slot name="timeline"></slot>
      </div>
      <div v-show="!isTimeline">
        <slot name="file"></slot>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: 'VideoPlaybackFile',
  data() {
    return {
      isTimeline: true
    };
  }
}
</script>

<style lang="scss" scoped>
.VodFooter {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
}
.TypeSwitcher {
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  & > .el-button {
    flex: 1;
    margin: 0;
    border-radius: 0px;
    font-size: 16px;
  }
}
.FooterContent {
  flex: 1;
  overflow: hidden;
  & > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5px 10px;
  }
}
</style>