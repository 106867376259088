<template>
  <div class="VodFileTable">
    <el-table :data="tableList" size="mini" border stripe>
      <el-table-column type="index" width="60" label="序号"></el-table-column>
      <el-table-column width="80" label="播放">
        <template v-slot="{row}">
          <ElIcon
            name="el-icon-video-play"
            :size="22"
            title="播放"
            @click="onPlayClick(row)"
          ></ElIcon>
        </template>
      </el-table-column>
      <el-table-column width="120" label="FTP上传">
        <template v-slot="{row}">
          <el-popover
            v-if="!row.serial"
            v-model="row.poperVisible"
            placement="top"
            title="选择上传时间段"
            width="245"
            trigger="manual"
            @show="onPoperShow(row)"
          >
            <el-button
              type="text"
              slot="reference"
              @click.stop="row.poperVisible = true"
            >FTP上传</el-button>

            <div>
              <div class="row">
                <div class="rowTitle">开始时间</div>
                <div class="rowContent">
                  <el-time-picker
                    size="mini"
                    v-model="ftpBegintime"
                    :clearable="false"
                    :default-value="dayjs(row.begintime).format('YYYY-MM-DD HH:mm:ss')"
                    :picker-options="{ selectableRange: ftpTimeRange(row.begintime, row.endtime)}"
                  ></el-time-picker>
                </div>
              </div>
              <div class="row">
                <div class="rowTitle">结束时间</div>
                <div class="rowContent">
                  <el-time-picker
                    size="mini"
                    v-model="ftpEndtime"
                    :clearable="false"
                    :default-value="dayjs(row.endtime).format('YYYY-MM-DD HH:mm:ss')"
                    :picker-options="{ selectableRange: ftpTimeRange(row.begintime, row.endtime)}"
                  ></el-time-picker>
                </div>
              </div>
              <div class="row color-red">
                <span>提示: 1,设备上传视频会消耗SIM流量, 请谨慎操作; 2,下发上传指令后, 请到[视频上传列表]查询</span>
              </div>

              <div class="row flex-end">
                <el-button @click.stop="row.poperVisible=false">取消</el-button>
                <el-button type="primary" @click="onFtpClick(row)">确定</el-button>
              </div>
            </div>
          </el-popover>

          <el-button
            v-if="row.serial"
            type="text"
            @click.stop="onFtpCancel(row)"
          >FTP上传取消</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="begintime" width="180" label="开始时间">
        <template v-slot="{row}">
          <label
            :title="row.begintime"
          >{{dayjs(row.begintime).format('YYYY-MM-DD HH:mm:ss')}}</label>
        </template>
      </el-table-column>
      <el-table-column prop="endtime" width="180" label="结束时间">
        <template v-slot="{row}">
          <label
            :title="row.endtime"
          >{{dayjs(row.endtime).format('YYYY-MM-DD HH:mm:ss')}}</label>
        </template>
      </el-table-column>
      <el-table-column prop="alarmSign" width="120" label="录像类型">
        <template v-slot="{row}">{{["全部(常规/报警)", "常规", "报警"][row.alarmSign]}}</template>
      </el-table-column>
      <el-table-column prop="device" width="200" label="终端设备"></el-table-column>
      <el-table-column prop="channel" width="120" label="通道">
        <template v-slot="{row}">
          <span :title="`${row.datatype},${row.codetype}`">{{newChannel(row.channel)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="storgetype" width="120" label="文件位置">
        <template v-slot="{row}">{{["终端设备", "云服务器存储"][0]}}</template>
      </el-table-column>
      <el-table-column prop="filesize" width="120" label="大小(MB)">
        <template v-slot="{row}">{{(row.filesize / 1024 / 1024).toFixed(2)}}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'VodFileTable',
  props: {
    fileList: {
      type: Array,
      default() {
        return [];
      }
    },
    ftpDown: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      ftpBegintime: new Date(),
      ftpEndtime: new Date(),
    };
  },
  mounted(){
    console.log('this.fileList',this.fileList);
  },
    watch:{
    tableList(){
      if(!this.tableList[0]) return;
      //监听到数据变化，播放第一个视频
      this.onPlayClick(this.tableList[0]);
    }
  },

  computed: {
    tableList() {
      return this.fileList.map(file => ({
        poperVisible: false,

        serial: '',
        ...file
      }));
    },
    newChannel(){
      return function(channel){
        switch (channel){
          case 1:
            return '右摄像头';
          case 2:
            return '后摄像头';
          case 3:
            return '左摄像头';
          case 4:
            return '前摄像头';
          case 5:
            return '驾驶室内摄像头';
          case 6:
            return '卷扬摄像头';
        }        
     }  
   }
  },
  methods: {
    dayjs,
    onPlayClick(row) {
      const {
        begintime, // 开始时间 (毫秒)
        endtime, // 结束时间 (毫秒)
        channel, // 通道号
        device, // 终端号
        //  码流类型 0：主码流; 1：子码流
        codetype,
        // 数据类型 0: 音视频; 1: 视频; 2: 双向对讲; 3: 监听; 4: 中心广播: 5; 透传
        datatype,
        // 文件名称, NOTE: (旧设备有)
        fileName,
      } = row;

      this.$emit('playClick', {
        // 开始时间, 请求视频 支持到秒
        begintime: Math.floor(begintime / 1000),
        // 结束时间, 请求视频, 支持到秒
        endtime: Math.floor(endtime / 1000),
        channel,
        device,
        codetype,
        datatype,
        fileName,
      });
    },
    ftpTimeRange(begintime, endtime) {
      return [
        dayjs(begintime).format('HH:mm:ss'),
        dayjs(endtime).format('HH:mm:ss')
      ].join(' - ');
    },
    onPoperShow(row) {
      this.ftpBegintime = dayjs(row.begintime).format('YYYY-MM-DD HH:mm:ss');
      this.ftpEndtime = dayjs(row.endtime).format('YYYY-MM-DD HH:mm:ss');
    },
    async onFtpClick(row) {
      row.poperVisible = false;

      const { ftpBegintime, ftpEndtime } = this;

      const begintime = dayjs(ftpBegintime).unix();
      const endtime = dayjs(ftpEndtime).unix();

      if (this.ftpDown) {
        const serial = await this.ftpDown({
          begintime,
          endtime,
          device: row.device,
          channel: row.channel,
          alarmSign: row.alarmSign,
          datatype: row.datatype,
          codetype: 0,
          storgetype: row.storgetype,
        });
        row.serial = serial;
      }
    },
    onFtpCancel(row) {
      this.$emit('fptCancel', {
        device: row.device,
        serial: row.serial,
        cmdType: 2, // 0: 暂停; 1: 继续; 2: 停止  
      });
      row.serial = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.VodFileTable {
  position: relative;
  width: 100%;
  height: 100%;
}

::v-deep .el-table {
  height: 100%;
  .el-table__body-wrapper {
    overflow-y: auto;
    height: calc(100% - 40px);
  }
}

.row {
  margin-top: 10px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.color-red {
  color: red;
}
</style>