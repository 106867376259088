<template>
  <div class="VodTimeLineWrapper" @mousewheel="onMouseWheel">
    <!-- 时间轴 -->
    <div ref="timeLineOuter" class="TimeLinesOuter">
      <!-- 时间轴 -->
      <ChannelTimeLine
        v-model="axisBasis"
        v-for="timeObj in channelList"
        :style="{height: `${(100 / channelList.length)}%`}"
        :key="timeObj.channel"
        :channel="timeObj.channel"
        :startPos="rangeStartPos"
        :endPos="rangeEndPos"
        :timeList="timeObj.timeList"
        @moving="onMoving"
        @click="onClick"
      ></ChannelTimeLine>
    </div>
    <!-- 时间刻度 -->
    <div class="TimeLineScale">
      <div
        :style="{width: `calc(100% / ${scaleValue.length-2}`}"
        class="ScaleCls"
        v-for="(scale,index) in scaleValue"
        :key="index"
      >
        <span class="borderLeft"></span>

        <template v-if="index % 2 == 0">
          <span class="borderLeft"></span>
          <span class="scaleNumber">{{scale}}</span>
        </template>

        <!-- 数组长度 偶数-->
        <template v-if="scaleValue.length % 2 == 0 && scale == scaleValue[scaleValue.length -1]">
          <span class="borderRight last"></span>
          <span class="borderRight"></span>
          <span class="borerRight lastNumber">{{scaleValue[scaleValue.length -1]+1}}</span> 
        </template>

        <!-- 数组长度 基数 -->
        <template v-if="scaleValue.length % 2 != 0 && scale == scaleValue[scaleValue.length -1]">
          <span class="borderRight lastRight"></span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash';
import ResizeObserver from '@/components/ResizeObserver';
import dayjs from 'dayjs';

import ChannelTimeLine from './ChannelTimeline.vue';

// 一小时3600000毫秒
const oneHourMs = 1000 * 60 * 60;
// 一天86400000毫秒
const oneDayMs = oneHourMs * 24;

// 时间轴类型
const ScaleType = {
  // 天, 显示24小时
  DAY: 1,
  // 小时, 显示60分钟
  HOUR: 2,
};

export default {
  name: 'VodTimeLine',
  components: {
    ResizeObserver,
    ChannelTimeLine,
  },
  props: {
    /**
     * 通道号数组
     */
    channels: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * 文件列表
     */
    fileList: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * 时间轴当天开始时间 (毫秒)
     */
    startPos: {
      type: Number,
      default: 0,
    },
    /**
     * 时间轴当天结束时间 (毫秒)
     */
    endPos: {
      type: Number,
      default: oneDayMs,
    },
    /**
     * TODO: 时间格式 
     */
    tipFormat: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      // 鼠标在时间轴上的占比, 只存储, 不做其他用处
      axisBasis: 0,
      // 时间轴类型 天/小时
      scaleType: ScaleType.DAY,
      // 当前时间 (鼠标滑动时间)
      currentPos: 0,

      innerStrtPos: 0,
      innerEndPos: 0,

      // 通道时间数据 Array<{channel:number,timeList:[]}>
      channelList: [],
      // 时间轴开始时间 (当天的开始时间或当前小时的开始时间)
      rangeStartPos: 0,
      // 时间轴结束时间 (当天的开始或当前小时的结束时间)
      rangeEndPos: 0,
      timeScale:[]
      // scaleList:[]
    };
  },
  computed: {
    /**
     * 时间轴刻度
     * 根据时间轴类型变化
     */
    scaleValue() {
      const { rangeStartPos, rangeEndPos} = this;
      // 开始时间
      var startHour = dayjs(rangeStartPos).hour();
      // 间隔小时数
      var intervalDay = dayjs(rangeEndPos).diff(rangeStartPos,'hour') + 1;
      var i1 = startHour;
      this.timeScale =[];
      for(var i = 0; i < intervalDay; i++) {
        var i2 = i1;
        if (i2 == 24) {
          i1 = 0
        }
        var i2 = i2 < 10 ? '0' + i2 : i2;
        i1 += 1;
        this.timeScale.push(i2);
      }
      const { scaleType } = this;
      if (scaleType === ScaleType.DAY) {
        console.log('scaleValvue',this.timeScale)
        return this.timeScale;
      } 
      else {
        // 小时时间轴
        var arr1 = new Array(60);
        for(var i=0;i<arr1.length;i++){
           arr1[i] = i<10? '0'+i:i;
        } 
        return arr1;
      }
    },
  },
  watch: {
    /**
     * 检测文件列表, 文件列表变化重新渲染
     */
    fileList() {
      this.scaleType = ScaleType.DAY;
      this.initRender();
    },
    // startPos() {
    //   this.scaleType = ScaleType.DAY;
    //   this.initRender();
    // },
    // endPos() {
    //   this.scaleType = ScaleType.DAY;
    //   this.initRender();
    // }
  },
  beforeMount() {
    this.initRender = throttle(this.initRender, 500).bind(this);

    // 初次渲染
    this.initRender();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    initRender() {
      this.setTimeRange();
      this.handleFileList();
    },

    /**
     * 处理文件列表
     */
    handleFileList() {
      const { rangeStartPos, rangeEndPos, channels } = this;
      const fileList = [...this.fileList];
      const channelList = channels.map(channel => ({ channel, timeList: [] }));

      const channelMap = new Map();

      while (fileList.length) {
        const file = { ...fileList.shift() };
        const channel = file.channel;

        if (!channels.includes(channel)) continue;

        // 超过 [rangeStartPos,rangeEndPos]
        if (file.endtime <= rangeStartPos
          || file.begintime >= rangeEndPos) continue;

        // 部分时间超出rangeStartPos
        if (file.begintime < rangeStartPos) {
          file.begintime = rangeStartPos;
        }
        // 部分时间超出rangeEndPos
        if (file.endtime > rangeEndPos) {
          file.endtime = rangeEndPos;
        }


        if (!channelMap.has(channel)) {
          channelMap.set(channel, []);
        }
        channelMap.get(channel).push(file);
      }

      [...channelMap.entries()]
        .forEach(([channel, files]) => {
          // 合并
          const timeList = this.mergeFileTime(files);
          channelList.find(p => p.channel === channel).timeList = timeList;
        });

      this.channelList = channelList;
    },
    /**
     * 根据时间轴类型, 改变时间轴的开始结束时间
     */
    setTimeRange() {
      const { scaleType, startPos, endPos, currentPos } = this;

      if (scaleType === ScaleType.HOUR
        && currentPos >= startPos
        && currentPos <= endPos) {

        const nowHourStart = dayjs(dayjs(currentPos).format('YYYY-MM-DD HH:00:00'));
        const nowHourEnd = dayjs(dayjs(currentPos).format('YYYY-MM-DD HH:59:59'));

        this.rangeStartPos = nowHourStart.valueOf();
        this.rangeEndPos = nowHourEnd.valueOf();

      } else {
        this.rangeStartPos = startPos;
        this.rangeEndPos = endPos;
      }

    },

    /**
     * 合并时间
     * 合并条件: 两个文件上个文件的结束时间和下个文件的开始相差小于1秒, 合并为一个文件
     */
    mergeFileTime(fileList = []) {

      if (!fileList.length) return;

      const timeList = [];

      let tmpList = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const nextFile = fileList[i + 1];

        tmpList.push(file);

        const diffMs = nextFile && Math.abs(nextFile.begintime - file.endtime);

        // > 1秒或结束位置, 结束合并
        if (!nextFile || diffMs > 1000) {
          timeList.push(tmpList);
          tmpList = [];
        }
      }

      return timeList.map(arr => ({
        startTime: arr[0].begintime,
        endTime: arr[arr.length - 1].endtime,
        fileList: arr
      }));
    },

    /**
     * 鼠标滚动
     */
    onMoving(channel, currentPos) {
      // console.log(channel, currentPos);
      this.currentPos = currentPos;
    },

    /**
     * 点击播放
     */
    onClick(currentPos, { channel, startTime, endTime, fileList }) {

      if (!fileList) return;

      const file = fileList[0];

      this.$emit('playClick', {
        // 开始时间, 请求视频 支持到秒
        begintime: Math.floor(currentPos / 1000),
        // 结束时间, 请求视频, 支持到秒
        endtime: Math.floor(endTime / 1000),
        channel,
        device: file.device,
        codetype: file.codetype,
        datatype: file.datatype,
        fileName: file.fileName,
      });

    },
    /**
     * 鼠标滚动, 改变时间轴类型
     */
    onMouseWheel(e) {
      const { scaleType } = this;
      if (e.wheelDelta > 0 && scaleType !== ScaleType.HOUR) { // 小时
        this.scaleType = ScaleType.HOUR;
        this.initRender();
      } else if (e.wheelDelta < 0 && scaleType !== ScaleType.DAY) { // 天
        this.scaleType = ScaleType.DAY;
        this.initRender();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.VodTimeLineWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.TimeLineScale {
  width: 100%;
  height: 32px;
  display: flex;
  font-size: 13px;
}

.TimeLinesOuter {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ScaleCls {
  position: relative;
  display: flex;
  flex-direction: column;
  & > span {
    height: 4px;
  }
  .scaleNumber {
    margin-left: -8px !important;
  }
}

.borderLeft {
  border-left: 1px solid #ccc;
}
.borderRight {
  border-right: 1px solid #ccc;
}
.last {
  margin-top: -4px;
  margin-left: 8px;
}
.lastRight {
  margin-top: -12px;
  margin-left: 8px;
}
.lastNumber {
  position: absolute;
  right: 0;
  transform: translateX(50%);
  margin-top: 8px;
}
</style>



